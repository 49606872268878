<template>
    <div class="exContentFour">
        <div class="ecfBox">
            <!-- 标题 -->
            <div class="ecfTitle wow animate__animated animate__slideInUp">
                <p class="ecfBig">互动锁客</p>
                <p class="ecfSmall">客户来到展位后，如何快速锁定客户，获得客户信息</p>
                <p class="ecfSmall">客户来到展位现场均希望获得企业介绍、产品详情、 现场演示、样品试用、促销优惠、展会礼品等众多信息。</p>
                <p class="ecfSmall">电子宣传册和展会小程序是现场客户自主了解相关信息的最佳方式，助力参展商提高服务效率，增加客户粘度。</p>
            </div>
            <!-- 图片 -->
            <div class="ecfImgs">
                <!-- 左图 -->
                <div class="ecfLeft wow animate__animated animate__slideInRight">
                    <img src="https://iv.vu818.com/img/pic-3-1.png" alt="" class="ecfLImg" />
                    <img src="https://iv.vu818.com/img/pic-2-2.png" alt="" class="ecfJt1" />
                </div>
                <!-- 中间 -->
                <div class="ecfCenter wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                    <img src="https://iv.vu818.com/img/pic-3-2.png" alt="" class="ecfCImg" />
                    <img src="https://iv.vu818.com/img/pic-2-2.png" alt="" class="ecfJt2" />
                </div>
                <!-- 右图 -->
                <div class="ecfRight wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                    <img src="https://iv.vu818.com/img/pic-3-3.png" alt="" class="ecfRImg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExContentFour',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.exContentFour {
    width: 100%;
    height: 700px;
    margin-top: 50px;
    .ecfBox {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        // 标题
        .ecfTitle {
            width: 70%;
            height: 145px;
            margin: 0 auto;
            .ecfBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-weight: 900;
                font-size: 36px;
                text-align: center;
                margin-bottom: 20px;
            }
            .ecfSmall {
                width: 100%;
                height: 25px;
                text-align: center;
            }
        }
        // 图片
        .ecfImgs {
            width: 910px;
            height: 500px;
            margin: 0 auto;
            margin-top: 50px;
            // 左图
            .ecfLeft {
                width: 400px;
                height: 100%;
                float: left;
                .ecfLImg {
                    float: left;
                    padding-top: 70px;
                }
                .ecfJt1 {
                    float: left;
                    padding-left: 50px;
                    padding-top: 220px;
                }
            }
            // 中间
            .ecfCenter {
                width: 300px;
                height: 100%;
                float: left;
                .ecfCImg {
                    float: left;
                    padding-top: 80px;
                }
                .ecfJt2 {
                    float: left;
                    padding-top: 220px;
                    padding-left: 20px;
                }
            }
            // 右图
            .ecfRight {
                width: 200px;
                height: 100%;
                float: left;
                text-align: center;
                .ecfRImg {
                    padding-top: 170px;
                }
            }
        }
    }
}
</style>
