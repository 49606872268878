<template>
    <div class="exContentOne">
        <img src="http://iv.vu818.com/img/0.jpg" alt="" class="ecoBg" />
        <div class="ecoBox">
            <!-- 标题 -->
            <div class="ecoTitle">线下展会及活动增效</div>
            <!-- 图文列表 -->
            <div class="ecoList">
                <ul>
                    <li class="ecoLi wow animate__animated animate__fadeInRight">
                        <!-- 图片 -->
                        <div class="ecoLiImg">
                            <img src="https://iv.vu818.com/img/01.png" alt="" class="ecoImg" />
                        </div>
                        <!-- 文字 -->
                        <div class="ecoLiText">
                            <p class="ecoT1">大展会，小展位，</p>
                            <p class="ecoT2">人流匆匆过，无人进店来。</p>
                        </div>
                    </li>
                    <li class="ecoLi wow animate__animated animate__fadeInRight" data-wow-delay="0.2s">
                        <!-- 图片 -->
                        <div class="ecoLiImg">
                            <img src="https://iv.vu818.com/img/02.png" alt="" class="ecoImg" />
                        </div>
                        <!-- 文字 -->
                        <div class="ecoLiText">
                            <p class="ecoT1">笑脸迎，客气送，</p>
                            <p class="ecoT2">礼品发放一大堆，真实获客没几个。</p>
                        </div>
                    </li>
                    <li class="ecoLi wow animate__animated animate__fadeInRight" data-wow-delay="0.3s">
                        <!-- 图片 -->
                        <div class="ecoLiImg">
                            <img src="https://iv.vu818.com/img/03.png" alt="" class="ecoImg" />
                        </div>
                        <!-- 文字 -->
                        <div class="ecoLiText">
                            <p class="ecoT1">看的多，问的少，</p>
                            <p class="ecoT2">名片资料转身丢，客户信息无处寻。</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExContentOne',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.exContentOne {
    width: 100%;
    height: 650px;
    position: relative;
    .ecoBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .ecoBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .ecoTitle {
            width: 30%;
            height: 50px;
            color: white;
            line-height: 50px;
            text-align: center;
            font-weight: 900;
            font-size: 36px;
            margin: 0 auto;
            padding-top: 50px;
        }
        // 图文列表
        .ecoList {
            width: 100%;
            height: 500px;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .ecoLi {
                    width: 25%;
                    height: 100%;
                    // 图片
                    .ecoLiImg {
                        width: 100%;
                        height: 60%;
                        text-align: center;
                        .ecoImg {
                        }
                    }
                    // 文字
                    .ecoLiText {
                        width: 100%;
                        height: 60px;
                        line-height: 30px;
                        text-align: center;
                        margin-top: 20px;
                        color: white;
                        .ecoT1 {
                            width: 100%;
                            height: 30px;
                            font-size: 20px;
                            font-weight: 900;
                        }
                        .ecoT2 {
                            width: 100%;
                            height: 30px;
                            font-size: 20px;
                            font-weight: 900;
                        }
                    }
                    .ecoList {
                    }
                }
            }
        }
    }
}
</style>
