<template>
    <div class="exContentSix">
        <!-- 标题 -->
        <div class="ecsTitle">数据分析</div>
        <!-- 图片列表 -->
        <div class="ecsList">
            <ul>
                <li class="ecsLi">
                    <img src="http://iv.vu818.com/img/esImg1.jpg" alt="" class="ecsImg" />
                </li>
                <li class="ecsLi">
                    <div class="ecsLIcon">
                        <img src="http://iv.vu818.com/img/esIcon1.png" alt="" class="ecsIcon" />
                    </div>
                    <p class="ecsP">访问概况</p>
                </li>
                <li class="ecsLi">
                    <img src="http://iv.vu818.com/img/esImg2.jpg" alt="" class="ecsImg" />
                </li>
                <li class="ecsLi">
                    <div class="ecsLIcon">
                        <img src="http://iv.vu818.com/img/esIcon2.png" alt="" class="ecsIcon" />
                    </div>
                    <p class="ecsP">移动访问</p>
                </li>
                <li class="ecsLi">
                    <div class="ecsLIcon">
                        <img src="http://iv.vu818.com/img/esIcon3.png" alt="" class="ecsIcon" />
                    </div>
                    <p class="ecsP">地域分布</p>
                </li>
                <li class="ecsLi">
                    <img src="http://iv.vu818.com/img/esImg3.jpg" alt="" class="ecsImg" />
                </li>
                <li class="ecsLi">
                    <div class="ecsLIcon">
                        <img src="http://iv.vu818.com/img/esIcon4.png" alt="" class="ecsIcon" />
                    </div>
                    <p class="ecsP">互动信息</p>
                </li>
                <li class="ecsLi">
                    <img src="http://iv.vu818.com/img/esImg4.jpg" alt="" class="ecsImg" />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExContentSix',
};
</script>

<style lang="less" scoped>
.exContentSix {
    width: 1423px;
    height: 600px;
    margin: 0 auto;
    margin-top: 50px;
    // 标题
    .ecsTitle {
        width: 30%;
        height: 50px;
        margin: 0 auto;
        line-height: 50px;
        text-align: center;
        font-size: 40px;
        font-weight: 900;
    }
    // 图片列表
    .ecsList {
        width: 100%;
        height: 500px;
        margin-top: 30px;
        ul {
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            .ecsLi {
                width: 25%;
                height: 50%;
                .ecsImg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .ecsLIcon {
                    width: 30%;
                    height: 40%;
                    margin: 0 auto;
                    margin-top: 50px;
                    border-radius: 50%;
                    box-shadow: 1px 1px 10px 1px gray;
                    text-align: center;
                    transition: 0.5s;
                    .ecsIcon {
                        width: 100px;
                        height: 100px;
                    }
                }
                .ecsP {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    font-size: 25px;
                }
            }
            .ecsLi:hover {
                .ecsLIcon {
                    transform: rotateY(180deg);
                }
            }
        }
    }
}
</style>
