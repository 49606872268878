<template>
    <div class="exContentThree">
        <div class="ecTBox">
            <!-- 标题 -->
            <div class="ecTTitle wow animate__animated animate__slideInUp">
                <p class="ecTBig">拓宽引流</p>
                <p class="ectSmall">在展会现场，如何让更多的客户到展位？</p>
                <p class="ectSmall">据权威机构统计：</p>
                <p class="ectSmall">曾寄发过邀请函的参展商获得客户青睐的几率比其他参展商大3-4倍。</p>
                <p class="ectSmall">通过游戏互动、领取礼品来吸引客户关注，是主流的引流方式。</p>
            </div>
            <!-- 图文 -->
            <div class="ecTImgText">
                <!-- 左图 -->
                <div class="ectLeft wow animate__animated animate__slideInRight">
                    <img src="https://iv.vu818.com/img/pic-2-1.png" alt="" class="ectLImg" />
                    <img src="https://iv.vu818.com/img/pic-2-2.png" alt="" class="jtJpg" />
                </div>
                <!-- 中间 -->
                <div class="ectCenter wow animate__animated animate__slideInRight" data-wow-delay="0.2s">
                    <img src="https://iv.vu818.com/img/pic-2-3.png" alt="" class="ectCImg" />
                    <img src="https://iv.vu818.com/img/pic-2-2.png" alt="" class="jtJpg2" />
                </div>
                <!-- 右图 -->
                <div class="ectRight wow animate__animated animate__slideInRight" data-wow-delay="0.3s">
                    <img src="https://iv.vu818.com/img/pic-2-4.png" alt="" class="ectRImg" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExContentThree',
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: 'wow',
            animateClass: 'animate__animated',
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.exContentThree {
    width: 100%;
    height: 700px;
    margin-top: 50px;
    background-color: white;
    .ecTBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .ecTTitle {
            width: 50%;
            height: 170px;
            margin: 0 auto;
            .ecTBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 36px;
                font-weight: 900;
                margin-bottom: 20px;
            }
            .ectSmall {
                width: 100%;
                height: 25px;
                line-height: 25px;
                text-align: center;
            }
        }
        // 图文
        .ecTImgText {
            width: 100%;
            height: 500px;
            margin-top: 20px;
            // 左图
            .ectLeft {
                width: 300px;
                height: 100%;
                float: left;
                text-align: center;
                .ectLImg {
                    float: left;
                    padding-top: 50px;
                }
                .jtJpg {
                    float: left;
                    padding-left: 20px;
                    padding-top: 220px;
                }
            }
            // 中间
            .ectCenter {
                width: 700px;
                height: 100%;
                float: left;
                .ectCImg {
                    padding-left: 50px;
                    padding-top: 55px;
                    float: left;
                }
                .jtJpg2 {
                    padding-left: 20px;
                    padding-top: 220px;
                    float: left;
                }
            }
            // 右图
            .ectRight {
                width: 280px;
                height: 100%;
                float: left;
                // text-align: center;
                .ectRImg {
                    padding-top: 120px;
                }
            }
        }
    }
}
</style>
