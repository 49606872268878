<template>
    <div class="exContentSeven">
        <div class="ecSBox">
            <!-- 标题 -->
            <div class="ecSTitle">展会案例</div>
            <!-- 图片列表 -->
            <div class="ecSImgList">
                <ul>
                    <li class="ecSLi" v-for="(el, i) in 4" :key="i">
                        <img src="http://iv.vu818.com/img/sj.png" alt="" class="esSj" />
                        <img src="http://iv.vu818.com/img/esBg1.jpg" alt="" class="ecSImg2" />
                        <div class="kuang">
                            <dl>
                                <dt class="dtImgs">
                                    <img class="dtImg" src="https://iv.vu818.com/img/alewm.jpg" alt="" />
                                </dt>
                            </dl>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ExContentSeven",
};
</script>

<style lang="less" scoped>
.exContentSeven {
    width: 100%;
    height: 700px;
    margin-top: 50px;
    .ecSBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
        // 标题
        .ecSTitle {
            width: 30%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-weight: 900;
            font-size: 36px;
            margin: 0 auto;
            margin-top: 25px;
        }
        // 图片列表
        .ecSImgList {
            width: 100%;
            height: 600px;
            margin-top: 20px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .ecSLi {
                    width: 20%;
                    height: 80%;
                    margin-top: 50px;
                    position: relative;
                    overflow: hidden;
                    .esSj {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                    }
                    .ecSImg2 {
                        position: absolute;
                        top: 13px;
                        left: 17px;
                        width: 87%;
                        height: 94%;
                        border-radius: 15px;
                        object-fit: cover;
                    }
                    .kuang {
                        position: absolute;
                        top: 14px;
                        left: 17px;
                        width: 87%;
                        height: 94%;
                        border-radius: 15px;
                        overflow: hidden;
                        dl {
                            width: 224px;
                            height: 451px;
                            position: absolute;
                            left: -225px;
                            top: 0px;
                            background-color: rgba(34, 31, 31, 0.5);
                            transition: 0.8s;
                            border-radius: 15px;
                            // opacity: 0;
                            overflow: hidden;
                            .dtImgs {
                                width: 100%;
                                height: 135px;
                                text-align: center;
                                position: relative;
                                top: 50%;
                                transform: translateY(-50%);
                                // margin-top: 80px;
                                .dtImg {
                                    height: 100%;
                                    border-radius: 10px;
                                }
                            }
                        }
                    }
                }
                .ecSLi:hover {
                    .kuang {
                        dl {
                            transform: translateX(225px);
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
</style>
